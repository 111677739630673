<template>
    <div class="c-file-manager c-file-manager--local">

            <div>
                <div v-if="(role == 'user' || role == 'admin') && !readOnly" class="c-file-manager__upload-btn" >
                    <div class="c-file-upload">
                        <label class="c-file-upload__label" :for="'file_'+uniqId" >
                            <input type="file"
                                   :id="'file_'+uniqId"
                                   :ref="'file_'+uniqId"
                                   @change="changeFileName()"
                                   multiple="multiple"
                                    accept="image/*, .pdf, .xlsx, .docx, .xls, .ppt, .doc, .odt, .ods, .rar, .zip">
                        </label>

                        <b-progress v-if="uploading" class="c-file-upload__progress" :value="uploadPercentage" :max="'100'" animated></b-progress>
                    </div>
                    <b-icon class="c-image-upload__add"
                            icon="plus-circle-fill"
                            variant="primary"
                    ></b-icon>
                </div>
                <div class="c-file-controller mt-2">
                    <div class="c-file-controller__file" v-for="(file) in data.records" :key="file.id">
                        <div class="c-file-controller__icon">
                            <b-icon v-if="file.type === 'audio/mpeg'"
                                    icon="file-earmark-music"
                                    variant="primary"></b-icon>

                            <b-icon v-else-if="file.type === 'video/mp4' || file.type === 'video/quicktime'"
                                    icon="file-earmark-play"
                                    variant="primary"></b-icon>

                            <b-icon v-else-if="file.type === 'application/zip' || file.type === 'application/x-rar-compressed' || file.type === 'application/x-rar'"
                                    icon="file-earmark-zip"
                                    variant="primary" ></b-icon>

                            <b-icon v-else-if="file.type === 'application/pdf'"
                                    icon="file-earmark-richtext"
                                    variant="primary" ></b-icon>

                            <img v-else-if="file.type === 'image/jpeg' ||
                                            file.type === 'image/png' ||
                                            file.type === 'image/gif' "
                                 :src="path + file.url" />

                            <b-icon v-else-if="file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                                                    file.type === 'application/vnd.ms-excel'"
                                    icon="file-earmark-spreadsheet"
                                    variant="primary"></b-icon>

                            <b-icon v-else-if="file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                                                                    file.type === 'application/vnd.ms-powerpoint'"
                                    icon="file-earmark-easel"
                                    variant="primary"></b-icon>

                            <b-icon v-else
                                    icon="file-earmark-text"
                                    variant="primary"></b-icon>
                        </div>


                        <b-input-group size="sm" class="c-file-controller__edit-name-input" v-if="editAble === file.id">
                            <b-form-input
                                    placeholder="Nový název"
                                    v-model="newFileName"
                                    type="text"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button variant="primary" @click="changeName()">Uložit</b-button>
                            </b-input-group-append>
                        </b-input-group>

                        <a v-else :href="path + file.url" :title="path + file.url" target="_blank" download>
                            {{file.name}}
                        </a>

                        <div class="c-file-controller__controls">
<!--
                            <b-button v-if="indexFile !== 0"
                                      class="c-file-controller__btn-on-hover"
                                      variant="primary"
                                      size="sm"
                                      @click="sortFile('up', file.sort)">
                                <b-icon icon="arrow-up" variant="white"></b-icon>
                            </b-button>
                            <b-button v-if="data.records.length-1 !== indexFile"
                                      class="c-file-controller__btn-on-hover"
                                      variant="primary"
                                      size="sm"
                                      @click="sortFile('down', file.sort)">
                                <b-icon icon="arrow-down" variant="white" ></b-icon>
                            </b-button>

                            <b-button variant="primary"
                                      title="Změnit název"
                                      size="sm"
                                      @click="setEditable(file.id, file.name)">
                                <b-icon icon="pencil-square" variant="white" ></b-icon>
                            </b-button>
-->

                            <span>{{file.upload_datetime.date | moment("HH:mm:ss DD/MM/Y")}}</span>

                            <b-button v-if="(role == 'user' || role == 'admin') && !readOnly"
                                    class="c-file-controller__delete-btn ml-2" size="sm" variant="danger"
                                    @click="deleteFile(file.id)">
                                <b-icon icon="trash"
                                        variant="white"
                                ></b-icon>
                            </b-button>

                        </div>
                    </div>
                </div>
            </div>

    </div>
</template>

<script>
    export default {
        name: "local-file-manager",
        props: {
            idForeignTableRow: Number,
            readOnly: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                path: process.env.VUE_APP_SERVER,
                file: [],
                data: [],
                uploadPercentage: 0,
                uploading: false,
                uniqId: null,
                editAble: null,
                newFileName: null,
                maxFileSize: 128,
            }
        },
        methods: {
            changeFileName() {

                this.file = this.$refs['file_'+this.uniqId].files;
                this.uploadFile();
            },

            setEditable(id,name) {
                this.newFileName = name;
                this.editAble = id;
            },

            async loadData() {
                try {
                    const response = await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/local-file-manager/list.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        method: 'GET',
                        params: {
                            'foreign_id': this.idForeignTableRow,
                        }
                    });

                    this.data = response.data;

                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }
            },

            async changeName() {
                try {

                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/local-file-manager/update-name.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        data: {
                            'id': this.editAble,
                            'name': this.newFileName,
                        },
                        method: 'PUT'
                    });

                    this.editAble = null;
                    this.newFileName = null;
                    this.loadData();

                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.success'));
                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }
            },

            async uploadFile() {

                this.uploading = true;

                let formData = new FormData();
                let totalFileSize = 0;
                for( var i = 0; i < this.$refs['file_'+this.uniqId].files.length; i++ ){
                    let file = this.$refs['file_'+this.uniqId].files[i];
                    
                    const fsize = file.size;
                    const file_s = Math.round((fsize / ( 1024 * 1024 )));
                    totalFileSize += file_s;

                    formData.append('file[' + i + ']', file);
                    
                }
    
                if (totalFileSize > this.maxFileSize) {
                    this.$eventHub.$emit('openMessageController', 'error', 'Max file size is 256 MB.');
                    return false;
                }

                formData.append('foreign_id', this.idForeignTableRow);
                
                try {
                    await this.$http.post( process.env.VUE_APP_SERVER + 'api/local-file-manager/add-file.php',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function( progressEvent ) {
                                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                            }.bind(this)
                        }
                    );

                    await this.loadData();
                    this.file = [];
                    this.$refs['file_'+this.uniqId].value = '';
                    this.uploading = false;
                    this.uploadPercentage = 0;

                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.file_uploaded'));
                    

                } catch (error) {
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                    this.file = [];
                    this.uploading = false;
                    this.uploadPercentage = 0;
                }
            },
            async deleteFile(id) {
                try {
                    if (confirm("Opravdu smazat?")) {
                        await this.$http({
                            url: process.env.VUE_APP_SERVER + 'api/local-file-manager/remove-item.php',
                            headers: {'content-type': 'application/x-www-form-urlencoded'},
                            data: {
                                'id': id
                            },
                            method: 'DELETE'
                        });
                        this.loadData();
                        this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.deleted'));
                    }

                } catch (error) {
                    this.loadData();
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }

            },

            async sortFile(direction, sort) {
                try {

                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/local-file-manager/sort-files.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        data: {
                            'sort': sort,
                            'direction': direction,
                            'foreign_id': this.idForeignTableRow,

                        },
                        method: 'POST'
                    });
                    this.loadData();

                } catch (error) {
                    this.$eventHub.$emit('openMessageController', 'error', this.$t('messages.error'));
                }
            },
        },
        computed: {
            role: function () {
                try {
                    return this.$store.getters.user.permission;
                } catch (err) {
                    return false
                }
            },
        },
        mounted() {
            this.loadData();
            this.uniqId = this._uid
        },
    }
</script>

<style lang="scss" scoped>

</style>
